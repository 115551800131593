$(document).ready(function() {
  $.datepicker.regional['ja'] = {
    closeText: "閉じる",
    prevText: "&#x3C;前",
    nextText: "次&#x3E;",
    currentText: "今日",
    monthNames: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
    monthNamesShort: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
    dayNames: [ "日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日" ],
    dayNamesShort: [ "日","月","火","水","木","金","土" ],
    dayNamesMin: [ "日","月","火","水","木","金","土" ],
    weekHeader: "週",
    dateFormat: "yy/mm/dd",
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: "年",
    minDate: new Date()
  };
  $.datepicker.setDefaults($.datepicker.regional['ja']);
  $('#search_shop_date, #sp_search_shop_date').datepicker({
    onSelect: function (e) {
      handleDate(e)
    },
  });
  $('#date').datepicker({
    onSelect: function(e) {
      $(this).trigger('changeDate');
    },
  });

  $('#clear_search, #clear_search_sp').on('click', (e) => {
    $(e.target).closest('form').find('.form_input').val('');
    $('#sp_area option').remove();
    $('#area option').remove();
    $("#sp_area").append($('<option>').html('選択してください').val(''));
    $("#area").append($('<option>').html('選択してください').val(''));
  });

  $('#frm-search-shop, #frm-search-shop-sp').submit( (e) => {
    $('<input />').attr('type', 'hidden')
      .attr('name', 'sort')
      .attr('value', $('.search-shop-result #sort').val())
      .appendTo(e.target);
    return true;
  });

  $('.search-shop-result #sort').on('change', () => {
    $('#frm-search-shop').submit();
  })
  function handleDate(e) {
    var side_menu_form = $("#search_shop_date, #sp_search_shop_date").closest("form").attr('id');
      $("#search_shop_date").val(e);
      $("#sp_search_shop_date").val(e);
     if (side_menu_form == 'frm-search-shop-sp' || side_menu_form == 'frm-search-shop')
     {
       $("#frm-search-shop-sp").valid();
       $("#frm-search-shop").valid();
     }
  }

  function fetchCourseData(options = {}) {
    return new Promise((resolve, reject) => {
      jQuery.ajax({
        type: "get",
        url: `/shops/courses`,
        data: {
          "shop_id":  $("#bespo_shop_id").val(),
          "number_of_people": options.number_of_people,
          "date": options.date
        },
        success: function (res) {
          resolve(res.courses || []);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          resolve([]);
        }
      });
    });
  }

  async function getCourses() {
    $("#loading_course").show();
    const courses = await fetchCourseData();
    $("#loading_course").hide();
    if (courses.length == 0) {
      $("#titleCourse").hide();
    } else {
      $("#titleCourse").show();
    }
    courses.forEach(course => {
      const courseElement = $(`
        <div class="container-course">
          <div class="img-course-container">
            <img src="${course.first_image}">
          </div>
          <div class="content-course">
            <div class="category-container">${
              course.categories.map(c => `<span class="category-item">${c.name}</span>`).join('')
            }</div>
            <div class="name-course">${escapeHTML(course.name)}</div>
            <span class="btn-detail-course">${$("#txt_button_detail_course").val()}</span>
            <span class="price-course">${changeFormatPrice(course.price)}円 <span class="tax-content">${$("#txt_tax").val()}</span></span>
          </div>
        </div>
      `);
      courseElement.find('.btn-detail-course').on('click', () => {
        openPopupDetailCourse(course)
      });
      $("#listCourses").append(courseElement);
    });
  }

  function emptyPopup() {
    $("#nameCourse").empty();
    $("#prCourse").empty();
    $("#priceCourse").empty();
    $("#timePeriod").empty();
    $("#numberCustomerLimit").empty();
    $("#stayTime").empty();
    $("#cookingItems").empty();
    $("#description").empty();
    $("#cancellation_policy").empty();
    $("#imageCourse").empty();
    $(`#courseCategories`).empty();
  }

  function openPopupDetailCourse(course) {
    emptyPopup();

    if (course.categories != null) {
      course.categories.forEach(category => {
        $(`#courseCategories`).append(`
          <span class="category-item">${category.name}</span>
        `)
      });
    }

    if (course.course_images != null) {
      course.course_images.forEach(element => {
        $("#imageCourse").append(`<div class="img-course-modal">
                                  <img src="${element.image_path}" /></div>`)
      })
    } else {
      $("#imageCourse").append(`<div class="img-course-modal">
                                  <img src="${course.first_image}" /></div>`)
    }

    $("#nameCourse").html(`${escapeHTML(course.name)}`)
    const coursePR = course.pr == null ? '' : course.pr;
    $("#prCourse").html(`${escapeHTML(coursePR)}`)
    $("#priceCourse").html(`${changeFormatPrice(course.price)}円 ${$("#txt_tax").val()}`)
    if (course.has_limit_customer) {
      $("#numberCustomerLimit").html(`${course.number_customer_min}～${course.number_customer_max} ${$("#txt_people").val()}`)
    } else {
      $("#numberCustomerLimit").html($("#txt_all_party_size").val())
    }
    if (course.has_reservable_time) {
      $("#timePeriod").html(`${course.reservable_form}～${course.reservable_to}`)
    } else {
      $("#timePeriod").html($("#txt_all_time").val())
    }
    $("#stayTime").html(`${course.stay_time} ${$("#txt_minute").val()}`)
    $("#itemDescription").html(`${escapeHTML(course.item_description == null ? '' : course.item_description)}`)
    $("#description").html(`${escapeHTML(course.description == null ? '' : course.description)}`)
    $("#cancellation_policy").html(`${escapeHTML(course.cancellation_policy == null ? '' : course.cancellation_policy)}`)
    $("#detail-course").modal('show')
    if (course.hide_footer) {
      $("#detail-course .modal-footer").hide();
    } else {
      $("#detail-course .modal-footer").show();
    }
  }

  function changeFormatPrice(price) {
    return new Intl.NumberFormat('ja-JP').format(price);
  }

  function escapeHTML(htmlStr) {
    if (htmlStr == null) return '';

    return htmlStr.replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;");
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function getCalendar(options = {}) {
    if (document.querySelector('.seat_calendar') === null) return;

    const start_date = options.start_date ? new Date(options.start_date) : new Date();
    const date_range = 14;
    const locale = $("#language").val() === 'ja' ? 'ja-JP' : 'en-US';
    $("#seat_calendar").empty();
    const seat_day_elements = {};
    let month = null;
    const formatDate = (date) => {
      if (month === date.getMonth()) {
        return `${date.getDate()}`
      } else {
        month = date.getMonth();
        return `${date.getMonth()+1}/${date.getDate()}`
      }
    }
    for (let i = 0; i < date_range; i++) {
      const date = addDays(start_date, i);
      const date_element = $(`
        <div class="seat_day">
          <div class="weekday ${date.getDay() === 0 ? 'sun' : '' } ${date.getDay() === 6 ? 'sat' : '' }">${date.toLocaleDateString(locale, {weekday: 'short'})}</div>
          <button disabled>
            <div class="date">${formatDate(date)}</div>
            <div class="availability"></div>
          </button>
        </div>
      `);
      date_element.find('button').on('click', () => {
        openTimetableModal({date: date.yyyymmdd()});
      });
      seat_day_elements[date.yyyymmdd().replace(/\//g, "-")] = date_element;
      $("#seat_calendar").append(date_element);
      if (i === 6) {
        $("#seat_calendar").append('<div class="flex_break"></div>');
      }
    }

    window.calendar_start_date = start_date;
    if (start_date <= new Date()) {
      $(".prev-week").css('visibility', 'hidden');
    } else {
      $(".prev-week").css('visibility', 'visible');
    }

    $("#loading_seat_calendar").show();
    jQuery.ajax({
      type: "get",
      url: `/shops/calendar`,
      data: {
        "shop_id": $("#bespo_shop_id").val(),
        "start_date": start_date.yyyymmdd(),
        "end_date": addDays(start_date, date_range - 1).yyyymmdd(),
      },
      success: function (res) {
        $("#loading_seat_calendar").hide();
        res.data.forEach(e => {
          if (e.reservable) {
            seat_day_elements[e.date]?.find('button').removeAttr('disabled');
            seat_day_elements[e.date]?.find('.availability').addClass('yes');
          } else {
            seat_day_elements[e.date]?.find('button').attr('disabled', true);
            seat_day_elements[e.date]?.find('.availability').addClass('no');
          }
        });
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $("#loading_seat_calendar").hide();
      }
    });
  }

  function openTimetableModal(options = {}) {
    // redirect to tablecheckweb if needed
    if(document.querySelector('#redirect_to_tablecheckweb').value === 'true') {
      jQuery.ajax({
        type: "post",
        url: `/shops/tablecheckweb`,
        data: {
          "id_code": $("#shop_id_code").val(),
          "date": options.date || null,
          "from_fb_insta": $("#from_fb_insta").val(),
        },
        success: function (res) {
          location.href = res.url;
        },
      });
      return;
    }

    // set default input
    const defaultDate = options.date || new Date().yyyymmdd();
    const defaultPerson = options.number_of_people || 2;
    $("#date").val(defaultDate);
    $("#number_of_people").val(defaultPerson);

    // reset timetable
    $("#seat-list").empty();
    $("#not-availability").hide();
    $("#only-seat-radio").hide();
    $("#list-timetable-courses").empty();
    $("#new-reservation-btn").prop('disabled', true).addClass('disabled');

    $("#modal-timetable").modal('show')
    getTimetableSeats({time: options.time});
  }

  function getTimetableSeats(options = {}) {
    $("#loading_timetable").show();
    jQuery.ajax({
      type: "get",
      url: `/shops/timetable`,
      data: {
        "shop_id": $("#bespo_shop_id").val(),
        "number_of_people": $("#number_of_people").val(),
        "date": $("#date").val(),
      },
      success: function (res) {
        $("#loading_timetable").hide();
        $("#seat-list").empty();
        res.data ||= [];
        if (res.data.length === 0) {
          $("#not-availability").show();
          $("#only-seat-radio").hide();
          $("#only_seat").prop('checked', false);
          $("#list-timetable-courses").empty();
        } else {
          $("#not-availability").hide();
          $("#only-seat-radio").show();
          $("#only_seat").prop('checked', true).trigger('change');
        }
        const seats = res.data.map(time => {
          const date = new Date(time);
          const button =  $(`
            <button type="button" class="time">${date.toTimeString().split(' ')[0].substring(0, 5)}</button>
          `);
          button.on('click', () => {
            $("#seat-list .time").removeClass('active');
            button.addClass('active');
            getTimetableCourses(time);
          });
          return button;
        });
        seats.forEach(seat => {
          $("#seat-list").append(seat);
        });

        // select default time
        if (seats.length > 0) {
          let selectedSeat = seats[Math.floor((seats.length-1)/2)]
          if (options.time) {
            selectedSeat = seats.find(seat => seat.text() === options.time);
          }
          selectedSeat.focus().click();
        }
        activeReservationBtn();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $("#loading_timetable").hide();
      }
    });
  }

  async function getTimetableCourses(time) {
    $("#loading_timetable_course").show();
    $("#timetable").val(time);
    const courses = await fetchCourseData({date: time, number_of_people: $("#number_of_people").val()});
    $("#loading_timetable_course").hide();
    $("#only_seat").prop('checked', true).trigger('change');
    $("#list-timetable-courses").empty();
    courses.forEach(course => {
      const courseElement = $(`
        <div class="container-course mt_medium">
          <label class="radio-btn-align" for="course-change_${course.id}">
            <input class="radio" id="course-change_${course.id}" type="radio" value="${course.id}" name="course_ids">
            <label class="radio_label cursor-pointer" for="course-change_${course.id}"></label>
          </label>
          <div class="img-course-container">
            <img src="${course.first_image}">
          </div>
          <div class="content-course">
            <div class="category-container">${
              course.categories.map(c => `<span class="category-item">${c.name}</span>`).join('')
            }</div>
            <div class="name-course">${escapeHTML(course.name)}</div>
            <span class="price-course">${changeFormatPrice(course.price)}円 <span class="tax-content">${$("#txt_tax").val()}</span></span>
            <span class="btn-detail-course">${$("#txt_button_detail_course").val()}</span>
          </div>
        </div>
      `);
      courseElement.find('.btn-detail-course').on('click', () => {
        course.hide_footer = true;
        openPopupDetailCourse(course)
      });
      courseElement.find('input[name=course_ids]').on('change', () => {
        $("#name_course").val(course.name);
        $("#course_cancellation_policy").val(course.cancellation_policy);
        activeReservationBtn();
      });
      $("#list-timetable-courses").append(courseElement);
      activeReservationBtn();
    });
  }

  function activeReservationBtn() {
    if ($("input[name=course_ids]:checked").val() != null && $("#timetable").val()) {
      $("#new-reservation-btn").prop('disabled', false).removeClass('disabled');
    } else {
      $("#new-reservation-btn").prop('disabled', true).addClass('disabled');
    }
  }

  // For shops/:id page
  if($("#page-shop-show").length > 0) {
    $(".open-timetable-modal").on('click', () => {
      $("#detail-course").modal('hide');
      openTimetableModal();
    });

    $(".seat_day").on('click', (e) => {
      openTimetableModal({date: $(e.currentTarget).data('date')});
    });

    $(".reload-timetable").on('change changeDate', getTimetableSeats);

    $("#only_seat").on('change', () => {
      $("#name_course").val('');
      $("#course_cancellation_policy").val('');
    });

    $(".prev-week").on('click', () => {
      getCalendar({start_date: addDays(window.calendar_start_date, -14)});
    });
    $(".next-week").on('click', () => {
      getCalendar({start_date: addDays(window.calendar_start_date, 14)});
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('access') === 'list') {
      const date = urlParams.get('date');
      const time = urlParams.get('time');
      const number_of_people = urlParams.get('number_of_people');
      openTimetableModal({date: date, time: time, number_of_people: number_of_people});
    }

    getCalendar();
    getCourses();
  }
});
