export function hello() {
    $(".is-number").keyup(function() {
        $(this).val(this.value.match(/[0-9]*/));
    });
}
function addErrorMessage(errorElement,message) {
    $(errorElement).html(message);
}
function emptyTimeSelector() {
    $('#time-selector').empty();
}
function hideTimeSelector() {
    $('#time-select-wrapper').hide();
}
function showTimeSelector() {
    $('#time-select-wrapper').show();
}
function showSearchButton(){
    $('#web-reservation-update-btn').hide()
    $('#seat-search-btn').show()
}
function showUpdatedButton(){
    $('#seat-search-btn').hide()
    $('#web-reservation-update-btn').show()
}
function extractTime(dateTimeString) {
   if(dateTimeString !== null){
       let dateTimeParts = dateTimeString.split("T");
       return dateTimeParts[1];
   }
   else return null;

}
function extractDate(dateTimeString) {
    if(dateTimeString !== null){
        let dateTimeParts = dateTimeString.split("T");
        return dateTimeParts[0];
    }
    else return null;
}
function showSearchModal(){
    $("#modal-timetable-checker").modal('show')
    $(".loader-content").show();
}
function hideSearchModal(){
    $(".loader-content").hide();
    $("#modal-timetable-checker").hide()
    $(".modal-backdrop").hide()
    $("body").css("overflow", "auto");
}

function showNumberError(){
    addErrorMessage('#number-error',"該当の日時にご指定の人数で席が確保できませ<br> ん。条件を変更してください。")
}
function hideNumberError(){
    addErrorMessage('#number-error',null)
}
function getSelectedDate() {
    return $('#date-selector').val();
}
function getSelectedTime() {
    return $('#time-selector').val();
}
function getSelectedNumberOfPeople() {
    return $('#people-selector').val();
}
function checkSeatForDefaultDate(){
    let originalDate = $('#reservation-start-date').data('reservation_start_date');
    jQuery.ajax({
        type: "get",
        url: `/shops/timetable`,
        data: {
            "shop_id": $('#bespo-shop-id-element').data('bespo_shop_id'),
            "number_of_people":getSelectedNumberOfPeople(),
            "date":originalDate,
        },
        success: function (res) {
            res.data ||= [];
            if (res.data.length === 0) {
                setSelectableDates(false)
            } else {
                setSelectableDates(true)
            }

        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
            setSelectableDates(false)
        }
    });
}
function getTimetableSeats(options = {}) {
    let selectedTime = $('#time-selector').val()
    showSearchModal()
    jQuery.ajax({
        type: "get",
        url: `/shops/timetable`,
        data: {
            "shop_id": $('#bespo-shop-id-element').data('bespo_shop_id'),
            "number_of_people": getSelectedNumberOfPeople(),
            "date": getSelectedDate(),
        },
        success: function (res) {
            hideSearchModal()
            res.data ||= [];
            if (res.data.length === 0) {
                emptyTimeSelector()
                showNumberError()
                showSearchButton()
            } else {
                hideNumberError();
                showTimeSelector()
                setSelectTime(res.data,selectedTime);
                showUpdatedButton()
            }

        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
            hideSearchModal()
        }
    });
}
function convertDateFormat(inputDate) {
    let parts = inputDate.split('/');
    return parts[0] + '-' + parts[1] + '-' + parts[2];

}
function setSelectableDates(defaultDate){
    let originalDate = $('#reservation-start-date').data('reservation_start_date');
    let currentDate = new Date();
    if(!defaultDate){
        $('#date-selector').append('<option value="">日付を選択してください</option>');
    }
    for (let i = 0; i < 30; i++) {
        let date = new Date(currentDate);
        date.setDate(currentDate.getDate() + i);
        let dateString = date.toISOString().slice(0,10); // Format: YYYY-MM-DD
        $('#date-selector').append('<option value="' + dateString + '">' + getDisplayDate(date) + '</option>');
    }
    if(defaultDate===true){
        $('#date-selector').val(convertDateFormat(originalDate))
    }
}
function getDisplayDate(date){
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + "月 " + day + " 日";
}

function  isUpdatePage(){
    let currentURL = window.location.href;
    return currentURL.includes("change")
}
function formatTime(isoTime) {
    let date = new Date(isoTime);
    let hours = date.getHours().toString().padStart(2, '0'); // Ensure hours always have 2 digits
    let minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure minutes always have 2 digits
    return hours + ':' + minutes;
}
function setSelectTime(data,selectedTime){
    emptyTimeSelector()
    for (let i = 0; i < data.length; i++) {
        let time = formatTime(data[i]);
        $('#time-selector').append('<option value="' + data[i] + '">' + time + '</option>');

    }
    if(selectedTime !== null){
        let extractedTime = extractTime(selectedTime)
        let timeOnlyData = data.map(dateTimeString => extractTime(dateTimeString));
        if(timeOnlyData.includes(extractedTime)){
            let selectedDateTime = extractDate(data[0])+'T'+extractedTime
            $('#time-selector').val(selectedDateTime)
        }
        else{
            emptyTimeSelector()
            showNumberError()
            showSearchButton()
        }
    }
}
$(document).ready(function() {
    if(isUpdatePage()){
        showSearchButton();
        hideTimeSelector();
        checkSeatForDefaultDate()
    }
    $('#date-arrow').click(function(event) {
        $('#date-selector').click();
        event.stopPropagation();
    });

    $(document).click(function() {
        $('#date-selector').removeClass('open');
    });
    let reservationCode = $('#reservation-data-element').data('reservation_code');
    let shopIdCode = $('#shop-data-element').data('shop_id_code');
    $('#date-selector').change(function() {
        showSearchButton()
    });
    $('#people-selector').change(function (){
       showSearchButton()
    })
    $('#seat-search-btn').click(function () {
        getTimetableSeats();
    })
    $('#web-reservation-cancel-btn').click(function() {
        let cancelModal =  $("#modal-cancel-web-reservation")
        cancelModal.modal({
            backdrop: 'static',
            keyboard: false
        });
        cancelModal.modal('show')
        $(".success-content").hide();
        $(".failure-content").hide();
        $(".loader-content").show();
        jQuery.ajax({
            type: "patch",
            url: `/shops/${shopIdCode}/reservations/web-cancel?t=${reservationCode}`,
            data: {
            },
            success: function (res) {
                $('#web-reservation-cancel-btn').prop('disabled', true).addClass('disabled');
                $(".loader-content").hide();
                $(".success-content").show();
                $(".modal-backdrop").show()
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                cancelModal.modal('dispose')
                cancelModal.modal({
                    backdrop: true,
                    keyboard: true
                })
                $(".loader-content").hide();
                $(".failure-content").show();
                cancelModal.on('hidden.bs.modal', function () {
                    $(".modal-backdrop").hide()
                });
            }
        });
    });
    $('#web-reservation-update-btn').click(function() {
        let updateModal =  $("#modal-update-web-reservation")
        updateModal.modal({
            backdrop: 'static',
            keyboard: false
        });
        updateModal.modal('show')
        $(".success-content").hide();
        $(".failure-content").hide();
        $(".loader-content").show();
        if(getSelectedDate() !== null && getSelectedTime() !== null && getSelectedDate() !== '' && getSelectedTime() !== ''){
            let dateTimeStr = getSelectedDate()+" "+ formatTime(getSelectedTime())
            jQuery.ajax({
                type: "patch",
                url: `/shops/${shopIdCode}/reservations/web-change?t=${reservationCode}`,
                data: {
                    start_time: dateTimeStr,
                    number_of_people: parseInt(getSelectedNumberOfPeople()),
                    shop_id_code: shopIdCode,
                },
                success: function (res) {
                    $(".loader-content").hide();
                    $(".success-content").show();
                    $(".modal-backdrop").show()
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    updateModal.modal('dispose')
                    updateModal.modal({
                        backdrop: true,
                        keyboard: true
                    })
                    $(".loader-content").hide();
                    $(".failure-content").show();
                    updateModal.on('hidden.bs.modal', function () {
                        $(".modal-backdrop").hide()
                    });
                }
            });
        }
        else{
            updateModal.modal('dispose')
            updateModal.modal({
                backdrop: true,
                keyboard: true
            })
            $(".loader-content").hide();
            $(".failure-content").show();
            updateModal.on('hidden.bs.modal', function () {
                $(".modal-backdrop").hide()
            });
        }
    });
});
