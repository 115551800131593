// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

Date.prototype.yyyymmdd = function() {
  return this.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels");
require('jquery');
require('webpack-jquery-ui/datepicker');
require('webpack-jquery-ui/css');
require('./shop');
require ('./jquery.validate');
require("flatpickr/dist/flatpickr.css")
require("bootstrap");

import { hello } from "./reservation";

import flatpickr from "flatpickr";
const ja = require("flatpickr/dist/l10n/ja.js").default.ja;

$(document).ready(function() {
  hello();
  flatpickr("#time", {
    locale: ja,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true
  });

  //js validation on search form
  $("#frm-search-shop").validate({
    rules: {
      "address": {
        required: true
      },
      "shop_name": {
        searchType: true
      },
      "area": {
        required: true
      },

      "date": {
        date: true,
        required: true
      },
      "time": {
        required: true,
        pastTime: true
      },
      "number_of_people": {
        required: true,
        number: true
      },
    },
    messages: {
      "address": {
        required: "※都道府県を入力してください"
      },

      "area": {
        required: "※エリアを入力してください"
      },

      "date": {
        required: "※日付を入力してください"
      },
      "time": {
        required: "※時間を入力してください"
      },
      "number_of_people": {
        required: "※人数を入力してください",
        number: "※人数は数値で入力してください"
      },
    },
    submitHandler: function(form) {
      $("#loading-search").css("display", "block")
      form.submit();
    },
    ignore: [],
    errorElement: 'li',
    errorClass: 'red-color label-error-custom',
    highlight: function (element) {
      $(element).closest('.form-control').addClass('has-error');
    },
    unhighlight: function (element) {
      $(element).closest('.form-control').removeClass('has-error');
    },
    errorPlacement: function (error, element) {
      error.appendTo(element.parent().parent().parent().parent('div').find(".error").find("#error_msg"))
    }
  });

  $("#frm-search-shop-sp").validate({
    rules: {
      "address": {
        required: true
      },
      "shop_name": {
        searchType: true
      },

      "area": {
        required: true
      },

      "date": {
        date: true,
        required: true
      },
      "time": {
        required: true,
        pastTime: true
      },
      "number_of_people": {
        required: true,
        number: true
      },
    },
    messages: {
      "address": {
        required: "※都道府県を入力してください"
      },

      "area": {
        required: "※エリアを入力してください"
      },

      "date": {
        required: "※日付を入力してください"
      },
      "time": {
        required: "※時間を入力してください"
      },
      "number_of_people": {
        required: "※人数を入力してください",
        number: "※人数は数値で入力してください"
      },
    },
    submitHandler: function(form) {
      $("#loading-search").css("display", "block")
      form.submit();
    },
    ignore: [],
    errorElement: 'li',
    errorClass: 'red-color label-error-custom',
    highlight: function (element) {
      $(element).closest('.form-control').addClass('has-error');
    },
    unhighlight: function (element) {
      $(element).closest('.form-control').removeClass('has-error');
    },
    errorPlacement: function (error, element) {
      error.appendTo(element.parent().parent().parent().parent('div').find(".error").find("#error_msg_sp"))
    }
  });

  //add date format validation
  var msg;
  var dynamicErrorMsg = function () {
    return msg;
  }
  $.validator.addMethod('date', function (value, element, param) {
    var at_date = new Date(value);
    if (value.match(/\d{4}(-|\/)\d{1,2}(-|\/)\d{1,2}/) && at_date != "Invalid Date") {
      var month = parseInt(value.split("/")[1]);
      var now_date = new Date((new Date()).toLocaleDateString('ja-JP', "YYYY/MM/DD"));
      if ((parseInt(at_date.getMonth() + 1)) != month) {
        msg = "※無効な日付は入力できません";
        return false;
      } else if (at_date == "Invalid Date" || at_date < now_date) {
        msg = "※日付には当日以降の日付を入力してください";
        return false;
      } else {
        return true;
      }
    } else {
      msg = "※無効な日付は入力できません";
      return false;
    }
  }, dynamicErrorMsg);

  //add past time validation
  $.validator.addMethod("pastTime", function (value, element, params) {
    try {
      var at_date = new Date($("#search_shop_date").val());
      var cur_date = new Date();
      var at_time_string = cur_date.toLocaleDateString('ja-JP', "YYYY/MM/DD");
      var at_time = new Date(at_time_string + " " + value);
      var cur_time = new Date(cur_date.getTime() + (30 * 60000));
      if ((at_date instanceof Date && !isNaN(at_date)) &&
        at_date.toLocaleDateString() == cur_date.toLocaleDateString()) {
        if (at_time.getTime() < cur_time.getTime()) {
          return false;
        }
      }
    } catch (e) {
    }
    return true;
  }, '※現在時刻よりも30分後の時間で検索してください');

  //validate shop_name based on search type
  $.validator.addMethod('searchType', function(value, element){
    var is_required = element.id == 'search_shop_name_required' || element.id == 'sp_search_shop_name_required';
    if(value == "" && is_required){
      return false
    }else{
      return true;
    }
  }, '※店名を入力してください');
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
